<template>
  <UiPopup
    :model-value="modelValue"
    title="Change lead stage?"
    description="You can move your lead to the appropriate column by setting a new status and add a note to timeline."
    primary-button-text="Save"
    secondary-button-text="Later"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <form ref="form" @submit.prevent>
      <UiInputTextField v-model="data.note" name="Name" placeholder="Add a note" class="mb-4" />
      <UiInputSelect
        v-model="data.stageId"
        :items="stageItems"
        name="Stage"
        placeholder="Select stage"
        class="mb-4"
        :error="v$.stageId.$errors[0] ? v$.stageId.$errors[0].$message.toString() : ''"
      />
    </form>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { PIPELINES } from '@/constants'
import type { InputItem, LibraryItem, Stage } from '@/types'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

let stages: Stage[] = []
const stageItems = ref<InputItem[]>([])

onNuxtReady(() => getStages())
const getStages = async () => {
  const pipeline = await useGetPipelineByCode(PIPELINES.SALES)
  stages = pipeline.stages
  stageItems.value = pipeline.stages.map((s: LibraryItem) => ({ value: s.id, text: s.name }))
}

const data = ref({
  note: '',
  stageId: null,
})

const rules = computed(() => ({
  stageId: { required: helpers.withMessage('The stage is required', required) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  const isValid = await v$.value.$validate()
  const stage = stages.find((s) => s.id === data.value.stageId)
  if (isValid) {
    emits('input', { ...data.value, stage })
    emits('update:modelValue', false)
  }
}
</script>

<style scoped></style>
